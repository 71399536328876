
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');

.home {
  background-color: rgb(230, 230, 230) ;/* This ensures a different background color */
  min-height: calc(100vh - 60px); /* Adjust to ensure it takes up full height minus navbar */
  padding: 20px;
  font-family: 'Noto Kufi Arabic', sans-serif;
  
  
}

.home-container {
  background-color: #2d2d2d;
  height: 100vh; /* Full viewport height */
  width: 100vw;  /* Full viewport width */
  position: absolute; /* Ensures it covers the full screen */
 
}


.home h1 {
  text-align:start;
  margin-bottom: 20px;
}

.home-table {
  width: 100%;
  border-collapse: collapse;
  margin: 60px 0;
  font-size: 1em;
  font-family: 'Noto Kufi Arabic', sans-serif;
  text-align: right;
  

  
}

.home-table th,
.home-table td {
  padding: 15px;
  border: 1.5px solid #ddd;
  
  
}

.home-table thead {
  background-color: #0a3d62;
  color: #ffffff;
}

.home-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.home-table tbody tr:hover {
  background-color: #eaeaea;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #01346b;
}

.pagination button:hover:not(.active) {
  background-color: #0056b3;
}
.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 35%;
  padding: 10px;
  border: 1.5px solid #ddd;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
}

.search-bar .search-icon-button{
  background: none;
  border: none;
  
  top: 50%;
  right: 30px;
  
  
  transform: translateY(-10%);
  transform: translateX(150%);
  color: #aaa;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.controls button {
  padding: 10px 30px;
  font-size: 15px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.controls button:hover {
  background-color: #0056b3;
}
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.form-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  height: 700px;
  max-width: 100%;
  padding-bottom: 40px;
}
.form-container1 {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  width: 500px;
  max-width: 100%;
}

.form-container2 {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 500px;
 
  max-width: 100%;
  padding-bottom: 40px;
}

.formprofile-container{
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  
  max-width: 100%;
  padding-bottom: 20px;
}

.form-container h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.form-container input  {
  width: 60%;
  padding: 6px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
  
}
.form-container2 input  {
  width: 80%;
  padding: 6px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
  
}

.form-container select {
  width: 25%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;

}

.form-buttons {
  display: flex;
  justify-content:space-around;
 
}

.form-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 500;
  margin-top: 4px;
}

.form1-buttons {
  display: flex;
  justify-content: space-around;
}

.form1-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 500;
  margin: 0 50px;
}

.form-buttons button[type="submit"] {
  background-color: #007bff;
  color: white;
}
.form1-buttons button[type="submit"] {
  background-color: #007bff;
  color: white;
}

.form-buttons button[type="submit"]:hover {
  background-color: rgb(46, 100, 193);
}

.form1-buttons button[type="submit"]:hover {
  background-color: rgb(46, 100, 193);
}

.form-buttons button[type="button"] {
  background-color: #dc3545;
  color: white;
  
}

.form1-buttons button[type="button"] {
  background-color: #dc3545;
  color: white;
  
}

.form-buttons button[type="button"]:hover {
  background-color: #c82333;
}

.form1-buttons button[type="button"]:hover {
  background-color: #c82333;
}



.delete-button,
.edit-button,
.Archive-button {
  padding: 0.5em 1em;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 5px;
  width: auto; /* Remove fixed width */
}
.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

.edit-button,
.Archive-button {
  background-color: #007bff;
}

.edit-button:hover,
.Archive-button:hover {
  background-color: #0056b3;
}



label{
  font-size: 18px;
    font-weight: 500;
}

.role-buttons, .departure-arrival-buttons , .active-buttons , .Priority {
  display:flex;
  align-items: center;
  justify-content:space-around; /* Distribute buttons evenly */
  width: 100%; /* Full width of the parent container */
  flex-wrap: wrap; /* Allows wrapping if there's not enough space */
}





.scrollable-select {
  max-height: 150px;
  overflow-y: auto;
}


/* Add this CSS to your existing stylesheet */

/* Apply base button styles */


.buttons1 .btn, .buttons2 .btn {
  flex: 1;
  margin: 5px; /* Adjust margin to space out buttons */
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: calc(33.33% - 20px); /* Adjust width and margin as needed */
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  background-color: rgb(46, 100, 193); /* Default button background */
  transition: all 0.3s linear;
  cursor: pointer;
  margin: 10px; /* Space out buttons */
  font-size: 17px;
  font-weight: 600;
}

.btn1 {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 180px; /* Adjust width and margin as needed */
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  background-color: rgb(46, 100, 193); /* Default button background */
  transition: all 0.3s linear;
  cursor: pointer;
  margin: 15px; /* Space out buttons */
  font-size: 17px;
  font-weight: 600;
}


/* Add hover, focus, and active styles */
.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: rgb(46, 100, 193); /* Background color on hover */
}

/* Style for selected button */
.btn.selected {
  background-color:white;
   /* Change background color if selected */
  color: rgb(46, 100, 193);
  border: 2.5px solid rgb(46, 100, 193);
}

.btn1:hover,
.btn1:active,
.btn1:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: rgb(46, 100, 193); /* Background color on hover */
}

/* Style for selected button */
.btn1.selected {
  background-color:white;
  /* Change background color if selected */
 color: rgb(46, 100, 193);
 border: 2.5px solid rgb(46, 100, 193);
}

.error-messages {
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-bottom: 5px;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s forwards;
}

.home-table1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
  font-family: 'Noto Kufi Arabic', sans-serif;
margin-top: 0px;
  text-align: right;
  
}

.home-table1 th,
.home-table1 td {
  padding: 15px 15px;
  border: 1.5px solid #2d2d2d;
  font-weight:550;
  
}

.home-table1 thead {
  /* background-color: #0a3d62; */
  background-color: #0968b5;
  color: #ffffff;
}
.home-table1 tbody {
  background-color: #000000 !important;
  color: white;
}
/* 
.home-table1 tbody tr:nth-child(even) {
  background-color: #323232;
}

.home-table1 tbody tr:hover { 
  background-color: #eaeaea;
} */

.inputlists {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-bottom: 1px solid #645979;
  outline: none;
  border-radius: 5px 5px 0 0;
  background-color: #ffffff;
  font-size: 16px;
}
.list {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
}
.list-items {
  padding: 10px 5px;
}
.list-items:hover {
  background-color: #dddddd;
}

.image1 {
  width: 180px;
  margin: 0 50px;
  align-items: center;
  justify-content: space-between;
  height: auto; /* Maintains the aspect ratio of the image */
  display: inline-block; /* Allows centering using text-align */
  margin-bottom: 10px;
}

.image2 {
  width: 240px;
  margin: 0 30px;
  align-items: center;
  justify-content: space-between;
  height: 180px;
  display: inline-block; /* Allows centering using text-align */
  margin-bottom: 10px;
}

.images-container {
  text-align: center;
  margin-top: 20px;
  
}

.imagecoda {
  width: 160px;
  margin-left: 20px;
  margin-bottom: -10px;
  
}


.eye-icon {
  position: absolute;
  right: 68%;
  bottom: 44.5%; /* Adjust based on your design */
  cursor: pointer;
  color: #000000;
   /* Adjust based on your design */
}

/* Add these styles to home.css */
.red {
  background-color: rgb(175, 33, 33) !important;
  color: white;
  font-weight: 550;
}

.green {
  background-color: rgb(9, 149, 121) !important;
  color: white;
  font-weight: 550;
}

/* home.css */
.red-background {
  /* background-color: rgb(175, 33, 33) !important; */
  background-color: rgb(175, 33, 33) !important;

  color: white;
  font-weight: 550; /* Optional: ensures text is readable */
}

.green-background {
  background-color: rgb(9, 149, 121) !important;
  color: white;
  font-weight: 550; /* Optional: ensures text is readable */
}

.default-background {
  background-color: rgb(233, 233, 233) !important;
  color: rgb(0, 0, 0);
  font-weight: 550; /* Optional: ensures text is readable */
}

.loadingV {
  color: white;
  margin-right: 10px;
}

.departure {
  background-color: #ccb21e; /* Light red for Departure */
  color: #ffffff;
}

.arrival {
  background-color: #000000; /* Light green for Arrival */
  color: #ffffff;
}

.profile-card {
  max-width: 500px;
  height: 155px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 120px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 50px;
}

.profile-photo1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}



.editprofile-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 550;
  position: absolute;
  bottom: 10px; /* Align it towards the bottom */
  right: 20px;
}
.profilename {
  margin-bottom: 80px;
}


.file-input-button {
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-input-button:hover {
  background-color: #0056b3;
}

.file-input {
  display: none;
}

.cropper-container {
  width: 100%;
  max-height: 400px;
  margin-bottom: 15px;
}

.crop-button {
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.crop-button:hover {
  background-color: #218838;
}

.input-group {
  width: 95%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 500;
  font-size: large;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}




.icon {
  cursor: pointer;
  margin: 0 7px;
  font-size: 20px;
  transition: color 0.3s ease;
}

.edit-icon {
  color: #007bff; /* Edit button color */
}

.delete-icon {
  color: #dc3545; /* Delete button color */
}

.archive-icon {
  color: #28a745; /* Archive button color */
}

.icon:hover {
  color: #0056b3; /* Hover color for all icons */
}

.action-column {
  background-color: rgb(43, 42, 42);
  text-align: center;
  vertical-align: middle;
  padding: 8px; /* Adjust padding if necessary */
}

@media (max-width: 600px) {
  .profile-card {
    padding: 15px;
    margin-top: 75px;
    
  }

  .file-input-button,
  .submit-button {
    width: 100%;
  }

 
}


@media (max-width: 768px) {
  .delete-button,
  .edit-button,
  .Archive-button {
    font-size: 0.8rem; /* Smaller font size on smaller screens */
    padding: 0.4em 0.8em;
    margin-right: 5px;
  }

  .home-table th,
  .home-table td {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .delete-button,
  .edit-button,
  .Archive-button {
    font-size: 0.7rem;
    padding: 0.3em 0.6em;
    margin-right: 5px;
    display: block; /* Stack buttons vertically */
    margin-bottom: 10px; /* Add spacing between stacked buttons */
  }

  .home-table {
    font-size: 0.9em;
  }

  .home-table th,
  .home-table td {
    padding: 8px;
  }
}
