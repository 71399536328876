
.navbar {
    width: 100%;
    background-color: white;
    color: white;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    position: fixed; 
    top: 0;
    z-index: 1000; 
  }
  
  .navbar-brand a {
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-admin {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    margin-left: 50px; 
  }
  
  .navbar-admin .iconrole {
    margin-left: 5px; 
  }
 
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar-links {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    .navbar-admin {
        margin-top:-10px;
    }
  }
  