/* src/pages/Login.module.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');



.container {
    position: relative;
    max-width: 1000px;
    width: 600px;
    border-radius: 6px;
    padding: 30px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.container header {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-align: center;
}

.container header::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    height: 3px;
    width: 100%;
    border-radius: 8px;
    background-color: #333;
}

.container form {
    position: relative;
    margin-top: 16px;
    min-height: 400px;
    background-color: #fff;
}

.container form .form {
    position: relative;
    background-color: #fff;
    transition: 0.3s ease;
}

.container form .title {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    margin: 6px 0;
    color: #333;
}

.container form .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.input-field {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 4px 0;
}

.input-field label {
    font-size: 18px;
    font-weight: 600;
    color: #2e2e2e;
}

.input-field input,
select {
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: #333;
    border-radius: 5px;
    border: 1px solid #aaa;
    padding: 0 15px;
    height: 42px;
    margin: 8px 0;
}

.input-field input:focus,
.input-field select:focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.input-field select,
.input-field input[type="date"] {
    color: #707070;
}

.input-field input[type="date"]:valid {
    color: #333;
}

.container form button,
.backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: calc(33.33% - 10px);
    border: none;
    outline: none;
    color: #fff;
    border-radius: 5px;
    background-color: rgb(46, 100, 193);
    transition: all 0.3s linear;
    cursor: pointer;
    margin: 10px;
    font-size: 16px;
}

.container form button:hover,
.backBtn:hover,
.container form button:active,
.backBtn:active,
.container form button:focus,
.backBtn:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.container form .btnText {
    font-size: 18px;
    font-weight: 600;
}

form button:hover {
    background-color: rgb(46, 100, 193);
}

form button i,
form .backBtn i {
    margin: 0 6px;
}

form .backBtn i {
    transform: rotate(180deg);
}

form .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

form .buttons2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.buttons2.clicked {
    background-color: rgba(55, 56, 70, 0.808);
    color: white;
}

form .buttons3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.error {
    color: red;
    font-weight: 400;
    
}
.eyeIcon {

    position: absolute;
    right: 95%;
    bottom: 10%;
    cursor: pointer;
    color: #000000;
     /* Adjust based on your design */
}

@media (max-width: 768px) {
    .container form .btnText {
        font-size: 15px;
        font-weight: 600;
    }
    .container {
        width: 320px;
    }
    .container form button,
.backBtn {
    width: 200px;
}
.eyeIcon {
    position:absolute;
    right: 90%;
    bottom: 14%; 
    cursor:pointer;
    color: #000000;
    
     
}
}