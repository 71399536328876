
body {
    direction: rtl; /* Ensure the whole application is in RTL */
  }
  
  .sidebar {
    width: 200px;
    background-color: #0a3d62;
    color: #ffffff;
    position: fixed;
    height: 100%;
    /* right: -250px;  */
    transition: right 0.3s;
    overflow-y: auto;
    z-index: 1000;
    top: 0px; /* Ensure the sidebar starts below the navbar */
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .sidebar-header {
    background-color: #ffffff;
    text-align: center;
  }
  
  .toggle-button {
    display: none;
    position: fixed;
    top: 10px;
    right: 10px; 
    background-color: transparent;
    color: black;
    border: none;
    box-shadow: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1001;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 20px;
    text-align: left;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .sidebar ul li a .icon {
    margin-left: 10px; 
  }
  .signout {
    position:absolute;
    display: inline-block;
    bottom: 10px;
    width: 80%;

  }

  


  .sidebar ul li:hover {
    background-color: #082c4c;
}
/*   
  @media (max-width: 768px) {
    .toggle-button {
      display: block;
    }
  
    .sidebar {
      right: -250px; 
      width: 250px;
      
    }
  
    .sidebar.open {
      right: 0;
    }
  
    .content {
      margin-right: 0;
    }
  } */
  