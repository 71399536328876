
.layout {
    display:flex;
    flex-direction:row; 
  }
  
  .content {
    flex-grow: 1; 
    padding: 20px;
    direction: rtl; 
    background-color: rgb(230, 230, 230); 
    margin-top: 60px; 
    min-height: calc(100vh - 60px); 
    margin-right: 180px;
  }
  .image-layout {
    width: 180px;
   margin-right: 45%;
   margin-bottom: -40px;
   
    
  }
  
  @media (max-width: 768px) {
    .content {
      margin-top: 60px; 
      padding-top: 20px;
      margin-right: -40px;
    }
    .image-layout {
      width: 180px;
     margin-right: 30%;
     margin-bottom: -40px;
     
      
    }
  }
  